.content
{
  img{
    margin: 0 5px;
  }

  .logout,
  .login{
    cursor: pointer;
  }
}
.logs-wrapper
{
  display: flex;
  flex-direction: column-reverse;
  height: 600px;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  overflow-y: scroll;
  background-color: black;
  color: #79f94f;
  font-family: 'Courier New', Courier, monospace;

  ul{
    list-style: none;    
    margin: 0;
  }
}
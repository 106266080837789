.facebook
{
  .profil
  {
    span{
      text-transform: uppercase;
    }
  }

  .managed_pages
  {
    ul{
      width: 400px;
      margin: 20px auto;
      padding: 0;
      list-style: none;
      text-align: left;

      li{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        margin: 5px 0;
        color: black;
        font-family: Arial, Helvetica, sans-serif;
      }
    }
    
    label{
      margin-left: 10px;
    }
  }
}
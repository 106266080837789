.extract-fields
{
  margin: 20px auto;
  width: 500px;

  form
  {
    width: 500px;

    div
    {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      padding: 5px 0;

      label{
        display: block;
        text-align: left;
        width: 150px;
        cursor: pointer;
      }

      input{
        width: 250px;
      }
    }
  }
}

.execution
{
  position: relative;
  height: 70px;

  button, svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  svg{
    width: 50px;
  }
}
.transformer
{
  h2{
    margin: 0px 0 10px;
  }

  .extract-sources-wrapper
  {
    display: flex;
    flex-flow: row wrap;  
    justify-content: space-around;
    margin: 0 50px;    

    ul{
      list-style: none;
      text-align: left;
      margin: 0;
      padding: 0;

      li{
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 25px;
        color: black;
        font-family: Arial, Helvetica, sans-serif;

        input[type=checkbox]{
          margin-right: 10px;
        }

        .bin{
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .execution
  {
    height: 50px;
    margin: 50px auto 0;

    button{
      width: 300px;
    }
  }
}
.loader
{
  .gsheet-identifier
  {
    height: 100px;    
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    label{
      margin-right: 10px;
      cursor: default;
    }

    .execution
    {
      position: relative;
      width: 300px;     

      svg{
        position: absolute;
      }
  
      button{
        width: 300px;
        margin: 0 0 0 10px;
      }
    }
  }

  .sheets-list
  {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0 50px 50px;

    ul{
      list-style: none;
      text-align: left;
      
      li{
        min-height: 25px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;
        font-family: Arial, Helvetica, sans-serif;

        input[type=checkbox]{
          margin-right: 10px;
        }

        .bin{
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  
  button{
    margin: 0;
  }
}
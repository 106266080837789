.content
{
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;

  img{
    margin: 0 5px;
  }

  .logout,
  .login{
    cursor: pointer;
  }

  .loader{
    position: absolute;
    width: 100%;
    height: 100%;    
    background-color: black;
    opacity: 0.5;
    border-radius: 5px;
    text-align: center;

    svg{
      margin: 6px 0;
    }

    &.hide{
      display: none;
    }
  }
}
@import './../../assets/scss/fonts.scss';

body{  
  width: 100%;
  margin: 0;
  padding: 0;

  h1, h2{
    margin: 0;
  }

  label, button{
    cursor: pointer;
  }

  .error{
    color: red;
    margin: 10px 0;
  }
  button.reset{
    margin: 10px 0;
  }
}

#root
{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  font-family: "Capriola";
  color: #1C5A59;

  header
  {
    .header-content
    {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      .titles{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
  
        h1, h2{
          margin: 0 25px;
        }
      }

      .authentications{
        display: flex;
        flex-flow: row;

        button{
          margin: 0 5px;
        }

        .separator{
          width: 1px;
          background-color: black;
          margin: 0 10px;
        }
      }
    }    

    nav{
      padding: 10px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      background-color: #2B7A74;

      .categories{
        height: 100px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;        
        border-left: solid 1px white;
        
        &:first-child {
          border-left: none;
        }

        h3{
          margin: 20px;
          color: white;
        }

        ul{
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          margin: 0 50px;
          padding: 0;
          list-style: none;
    
          li{
            width: auto;
            text-align: center;
    
            a{
              display: flex;
              flex-flow: row;
              align-items: center;
              gap: 5px;
              padding: 5px;
              margin: 0 5px;
              border-radius: 10px;
              border: solid 1px #1C5A59;
              color: #1C5A59;
              background-color: #FAF7F0;
              text-decoration: none;

              &.on{
                color: white;
                border-color: white;
                background-color: #1C5A59;

                img{
                  filter: invert(100%);
                }

                svg{
                  stroke: white;
                }
              }

              svg{
                stroke: #1C5A59;
              }
            }
          }
        }
      }      
    }
  }
  
  section.content-wrapper{
    text-align: center;
    margin: 30px 0 50px;
  }
  
  footer{
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    color: white;
    background-color: #1C5A59;
    padding: 10px 0;
    height: 60px;
  }
}